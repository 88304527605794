@media (max-width: 847px) {

    .hamburger-button {
        background-color: unset;
        display:inline-block;
        border: unset;
        color: black;
        padding: 0 0.75rem !important;
    }

    .p-button:enabled:hover {
        background: unset !important;
        border: unset !important;
    }


    .bar1, .bar2, .bar3 {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        background-color: rgb(10, 87, 110);;
        transition: all 0.5s ease-in-out;
    }

    .hamburger-button.active .bar1 {
        transform: translateY(8px) rotate(135deg);
        transition: 0.5s ease-out;
    }

    .hamburger-button.active .bar2 {
        opacity: 0;
        transition: 0.2s ease-out;
    }

    .hamburger-button.active .bar3 {
        transform: translateY(-8px) rotate(-135deg);
        transition: 0.5s ease-out;
    }
}