.dsgvo {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 0 0 3px 3px;
}

.dsgvo-label {
    padding: 0 0 0 50px;
    font-size: xxx-large;
    color: rgb(10, 87, 110);
    font-weight: bold;
}

.dsgvo-container {
    width: 100%;
    height: 100%;
    max-width: 1024px;
}

.dsgvo-text {
    padding: 50px;
    max-width: 1024px;
    height: 100%;
    width: 100%;
    hyphens: auto;
}

.dsgvo-text h2 {
    font-size: large;
}

.dsgvo-text h3 {
    font-size: medium;
}

.dsgvo-text p a {
    word-break: break-all;
}

.dsgvo-back-button-top-container, .dsgvo-back-button-bottom-container, .dsgvo-cookies-button-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.dsgvo-back-button-top {
    margin: 170px 20px 30px 0;
    background-color: unset;
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.dsgvo-cookies-button {
    margin: 0 20px 30px 0;
    background-color: unset;
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.cookies-table {
    width: 100%;
    max-width: 750px;
    padding: 15px;
}

.dsgvo-back-button-bottom {
    margin: 150px 20px 80px 0;
    background-color: unset;
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.dsgvo-back-button-bottom .p-button-label, .dsgvo-back-button-top .p-button-label, .dsgvo-cookies-button .p-button-label {
    padding: 5px;
}

.dsgvo-back-button-top:enabled:hover, .dsgvo-back-button-bottom:enabled:hover, .dsgvo-cookies-button:enabled:hover {
    background: rgba(10, 87, 110, 0.7);
    border-color: rgb(10, 87, 110);
}

.dsgvo-back-button-top:enabled:active, .dsgvo-back-button-bottom:enabled:active, .dsgvo-cookies-button:enabled:active {
    background: rgb(10, 87, 110);
    border-color: rgb(10, 87, 110);
}

.dsgvo-back-button-bottom .p-button-label:hover, .dsgvo-back-button-top .p-button-label:hover, .dsgvo-cookies-button .p-button-label:hover {
    color: white;
}

.dsgvo-back-button-bottom .p-button-label:active, .dsgvo-back-button-top .p-button-label:active, .dsgvo-cookies-button .p-button-label:active {
    color: white;
}

@media (max-width: 900px) {

    .dsgvo-label {
        font-size: x-large;
        padding: 0 0 0 25px;
    }

    .dsgvo-text {
        padding: 25px;
    }

    .dsgvo-text h2 {
        font-size: large;
    }

    .dsgvo-text h3 {
        font-size: medium;
    }

    .dsgvo-back-button-top, .dsgvo-back-button-bottom, .dsgvo-cookies-button {
        font-size: small;
    }

    .cookies-table {
        width: 100%;
    }

    .dsgvo-back-button-bottom {
        margin: 50px 20px 80px 0;
    }
}

@media (max-width: 300px) {
    .dsgvo-text {
        padding: 15px;
        max-width: 1024px;
        height: 100%;
        width: 100%;
    }

    .dsgvo-back-button-top {
        margin-top: 160px;
    }

    .dsgvo-label {
        font-size: large;
        margin-left: 10px;
    }

    .dsgvo-text h2 {
        font-size: medium;
    }

    .dsgvo-text h3 {
        font-size: small;
    }

    .dsgvo-text p {
        font-size: small;
    }

    .dsgvo-text ul {
        font-size: small;
    }
}