.opening-hours {
    z-index: 10;
    width: 100%;
    background-color: rgb(10, 87, 110);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.opening-hours-container {
    width: fit-content;
    height: fit-content;
    margin: 75px 0;
    padding: 20px;
    border-radius: 5px;
    background-image: url("../resources/hand-in-hand.jpg");
    background-size: 100%;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
}

.opening-hours-label {
    font-size: xxx-large;
    margin-bottom: 20px;
    font-weight: 600;
}

.weekday-and-opening-hours-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.weekday-container {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.weekday-container div, .hours-container div {
    margin-bottom: 5px;
}

.weekday-and-opening-hours {
    display: flex;
}

.notdienst {
    margin-top: 15px !important;
}

.cov-info {
    color: red;
    text-shadow: 0 0 5px black;
    display: flex;
    text-align: center;
    font-size: x-large;
    max-width: 250px;
    margin: 20px;
}

@media (max-width: 847px) {
    .opening-hours {
        display: flex;
        align-items: center;
    }

    .opening-hours-container {
        padding: 10px;
    }

    .opening-hours-label {
        font-size: large;
        margin-bottom: 20px;
    }

    .weekday-and-opening-hours {
        font-size: small;
    }

    .cov-info {
        font-size: large;
    }
}

@media (max-width: 298px) {
    .weekend {
        margin-top: 23px;
    }
}

@media (max-height: 635px) {
    .opening-hours-container {
        padding: 2px;
    }

    .opening-hours-label {
        font-size: medium;
        margin: 5px;
    }

    .cov-info {
        font-size: medium;
        margin: 5px;
    }

    .weekday-and-opening-hours-container * {
        font-size: x-small;
    }
}