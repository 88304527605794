.footer {
    z-index: 10;
    bottom: 0;
    background-color: rgb(10, 87, 110);
    color: white;
    width: 100%;
    font-size: 12px;
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0 -15px 20px rgb(129, 137, 144);
    border-radius: 3px 3px 0 0;
}

.footer * {
    color: white;
}

.adress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 5px;
    ;
    font-size: 12px;
}

.footer-contact {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.footer-contact a, div {
    color: white;
}

.footer-phone-container {
    margin: 0 10px 0 20px;
    font-size: 12px;
}

.footer-mail-container {
    margin: 0 10px 0 17px;
    font-size: 12px;
}

b div {
    color: white;
    font-weight: unset !important;
    cursor: pointer;

}

.impressum-dsgvo-container {
    display: flex;
    height: 80px;
    flex-direction: row;
    justify-content: center;
    margin: 0 0 0 7px;
    align-items: center;
    align-content: center;
}

.impressum-button-container {
    margin: 0 10px;
}

.dsgvo-button-container {
    margin: 0 5px 0 10px;
}

.impressum-button, .dsgvo-button {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 1024px) {

    .footer {
        flex-direction: column;
        font-size: small;
        height: unset;
    }

    .adress {
        font-size: small;
        flex-direction: column;
        margin-top: 20px;
    }

    .adress-head {
        margin-bottom: 3px;
    }

    .footer-contact {
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        font-size: small;
    }

    .footer-contact a, div {
        color: white;
    }

    .footer-phone-container {
        margin: 0 10px 0 65px !important;
        font-size: small;
    }

    .footer-mail-container {
        margin: 0 0 0 10px !important;
        font-size: small;
    }

    .impressum-dsgvo-container {
        margin: 0 0 20px 0;
        width: 100%;
        height: unset;
    }
}

@media (max-width: 850px) {

    .footer {
        box-shadow: 0 -10px 20px rgb(129, 137, 144);
    }
}

@media (max-width: 450px) {

    .footer-contact {
        flex-direction: column;
    }

    .footer-phone-container {
        margin: 0 10px 0 0 !important;
        font-size: small;
    }

    .footer-mail-container {
        margin: 0 0 0 10px !important;
        font-size: small;
    }
}