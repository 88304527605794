.first-header {
    z-index: 1001;
    position: fixed;
    overflow: hidden;
    top: 0;
    background-color: rgba(10, 87, 110, 0.8);
    width: 100%;
    max-width: 1024px;
    height: 25px;
    transition: height 0.5s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.first-header-invisible {
    transition: height 0.5s;
    height: 0;
}

.header-contact {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 25px;
}

.header-contact a, div {
    color: white;
}

.phone-container {
    margin: 0 10px 0 20px;
    font-size: 14px;
}

.mail-container {
    margin: 0 0 0 20px;
    font-size: 14px;
}

@media (max-width: 847px) {
    .first-header {
        justify-content: center;
        align-content: center;
    }

    .header-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        height: 25px;
    }

    .header-contact a, div {
        color: white;
    }

    .phone-container {
        margin: 0 10px 0 0;
        font-size: 12px;
    }

    .mail-container {
        margin: 0 0 0 10px;
        font-size: 12px;
    }
}

@media (max-width: 371px) {

    .first-header {
        height: 50px;
        display: flex;
        align-items: center;
    }

    .first-header-invisible {
        transition: 0.5s;
        height: 0;
    }

    .header-contact {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 25px;
    }
}