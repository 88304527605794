.cookies-inner-container {
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
}

.cookies-banner {
    z-index: 9999;
    background-color: white;
    top: 62px;
    position: absolute;
    max-width: 1000px;
    height: fit-content;
    width: 98%;
    box-shadow: 0px 0px 50px rgba(13, 103, 130, 0.6);
    border-radius: 2px 2px 4px 4px;
    border-color: rgb(10, 87, 110);
    border-style: solid;
    border-width: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.banner-strip {
    top: 0;
    width: 100%;
    height: 25px;
    border-style: solid;
    border-width: 5px;
    border-radius: 2px 2px 0 0;
    background-color: rgb(10, 87, 110);
}

.banner-body {
    margin: 15px 25px;
    display: flex;
    flex-direction: column;
}


.banner-label {
    padding: 10px;
    font-size: 17px;
    font-weight: bold;
    color: rgb(10, 87, 110);
}

.banner-text {
    padding: 10px;
    margin-bottom: 10px;
    color: rgb(10, 87, 110);
}

.cookies-button-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cookies-every-container, .cookies-necessary-container, .cookies-dsgvo-impressum-container {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    width: 100%;
    justify-content: flex-end;
}

.cookies-dsgvo-impressum-container {
    align-items: center;
    width: fit-content;
    margin-top: 10px;
}

.cookies-button-every {
    background-color: rgba(255, 255, 255, 0.6);
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.cookies-button-necessary {
    background-color: rgba(255, 255, 255, 0.6);
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.cookies-button-dsgvo {
    background-color: rgba(255, 255, 255, 0.6);
    border: unset;
    padding: unset;
    margin-bottom: 5px;
    font-size: small;
}

.cookies-button-impressum {
    background-color: rgba(255, 255, 255, 0.6);
    border: unset;
    padding: 3px 5px;
    font-size: small;
}

.cookies-button-necessary .p-button-label, .cookies-button-every .p-button-label, .cookies-button-dsgvo .p-button-label {
    padding: 5px;
}

.cookies-button-every:enabled:hover, .cookies-button-necessary:enabled:hover, .cookies-button-dsgvo:enabled:hover, .cookies-button-impressum:enabled:hover {
    background: rgba(10, 87, 110, 0.7);
    border-color: rgb(10, 87, 110);
}

.cookies-button-every:enabled:active, .cookies-button-necessary:enabled:active, .cookies-button-dsgvo:enabled:active, .cookies-button-impressum:enabled:active {
    background: rgb(10, 87, 110);
    border-color: rgb(10, 87, 110);
}

.cookies-button-necessary .p-button-label:hover, .cookies-button-every .p-button-label:hover, .cookies-button-dsgvo .p-button-label:hover, .cookies-button-impressum .p-button-label:hover {
    color: white;
}

.cookies-button-necessary .p-button-label:active, .cookies-button-every .p-button-label:active, .cookies-button-dsgvo .p-button-label:enabled:active, .cookies-button-impressum .p-button-label:enabled:active {
    color: white;
}

@media (max-width: 800px) {

    .banner-label {
        font-size: 13px;
    }

    .banner-text {
        font-size: 11px;
    }

    .cookies-button-necessary .p-button-label, .cookies-button-every .p-button-label {
        font-size: small;
    }

    .cookies-button-dsgvo .p-button-label, .cookies-button-impressum .p-button-label {
        font-size: small;
    }
}

@media (max-height: 600px) {

    .cookies-banner {
        top: 2px;
    }

    .banner-body {
        margin-top: unset;
    }

    .banner-label {
        padding-bottom: unset;
        font-size: small;
    }

    .banner-text {
        font-size: x-small;
    }

    .cookies-every-container, .cookies-necessary-container {
        display: flex;
        width: 40%;
        justify-content: flex-end;
    }

    .cookies-button-dsgvo, .cookies-button-impressum {
        margin: 0 25px;
    }

    .cookies-every-necessary-button-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }

    .cookies-dsgvo-impressum-container {
        flex-direction: row;
        width: 100%;
        justify-content: flex-start;
    }

    .cookies-button-impressum {
        padding: 5px;
    }

    .cookies-button-dsgvo {
        margin: unset;
    }

    .cookies-button-necessary .p-button-label, .cookies-button-every .p-button-label {
        font-size: small !important;
    }

    .cookies-button-dsgvo .p-button-label, .cookies-button-impressum .p-button-label {
        font-size: small !important;
    }

    .cookies-inner-container {
        justify-content: center;
    }
}