.impressum {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 0 0 3px 3px;
}

.impressum-label {
    padding: 0 0 0 50px;
    font-size: xxx-large;
    color: rgb(10, 87, 110);
    font-weight: bold;
}

.impressum-text * {
    hyphens: auto;
}

.impressum-text * a {
    word-break: break-all;
}

.impressum-container {
    padding: 50px;
}

.impressum-headline {
    font-size: large;
}

.impressum-back-button-top-container, .impressum-back-button-bottom-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.impressum-back-button-top {
    margin: 150px 20px 30px 0;
    background-color: unset;
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.impressum-back-button-bottom {
    margin: 150px 20px 80px 0;
    background-color: unset;
    border-color: rgb(10, 87, 110);
    padding: unset;
}

.impressum-back-button-bottom .p-button-label, .impressum-back-button-top .p-button-label {
    padding: 5px;
}

.impressum-back-button-top:enabled:hover, .impressum-back-button-bottom:enabled:hover {
    background: rgba(10, 87, 110, 0.7);
    border-color: rgb(10, 87, 110);
}

.impressum-back-button-top:enabled:active, .impressum-back-button-bottom:enabled:active {
    background: rgb(10, 87, 110);
    border-color: rgb(10, 87, 110);
}



.impressum-back-button-bottom .p-button-label:hover, .impressum-back-button-top .p-button-label:hover {
    color: white;
}

.impressum-back-button-bottom .p-button-label:active, .impressum-back-button-top .p-button-label:active {
    color: white;
}

@media (max-width: 900px) {

    .impressum-label {
        font-size: x-large;
        padding: 0 0 0 25px;
    }

    .impressum-container {
        padding: 25px;
    }
    
    .impressum-headline {
        font-size: large;
    }

    .impressum-back-button-top, .impressum-back-button-bottom {
        font-size: small;
    }

    .impressum-back-button-bottom {
        margin: 50px 20px 80px 0;
    }
}

@media (max-width: 300px) {
    .impressum-back-button-top {
        margin-top: 160px;
    }

    .impressum-label {
        font-size: large;
    }

    .impressum-container {
        margin: 10px;
    }

    .impressum-headline {
        font-size: medium;
    }
}