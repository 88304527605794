.contact {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 9;
    background-size: 30%;
    background-position: 100% 100.3%;
    background-repeat: no-repeat;
    background-image: url(../resources/mouse-with-an-apple.jpg);
    background-color: white;

}

.contact * {
    color: rgb(10, 87, 110)
}

.contact-with-map {
    width: 100%;
    height: 80%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
}

.contact-container {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.contact-adress-container {
    padding: 10px;
    height: fit-content;
    width: fit-content;
    box-shadow: 1px 1px 3px rgba(10, 87, 110, 0.8);
    border-radius: 5px;
}

.contact-phone-mail-container {
    padding: 10px;
    height: fit-content;
    display: flex;
    width: fit-content;
    box-shadow: 1px 1px 3px rgba(10, 87, 110, 0.8);
    border-radius: 5px;
    margin-left: 10px;
}



.cursor-pointer {
    cursor: pointer;
}

.contact-adress-button:active {
    box-shadow: -1px -1px 3px rgba(10, 87, 110, 0.8);
}

.contact-praxis {
    font-weight: bold;
}

.phone-and-mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 150px;
}

.phone-and-mail a {
    margin: 0 5px;
}

.contact-label {
    font-size: xx-large;
    font-weight: bold;
    margin: 0 15px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.contact-adress {
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.map-container {
    width: 1024px;
    height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.map {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    border-radius: 5px;
}

.map-png-container {
    width: 75%;
    height: 80%;
    box-shadow: 0 0 8px rgba(10, 87, 110, 0.8);
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.map-png {
    border-radius: 5px;
    width: 100%;
    height: auto;
}

.map-active {
    border-radius: 5px;
    width: 100% !important;
    height: 100% !important;
}

.gm-style-iw {
    padding: unset !important;
}

.gm-style-iw-d {
    padding: 8px !important;
    overflow: unset !important;
}

.gm-ui-hover-effect {
    display: none !important;
}

@media (max-width: 1024px) {

    .contact-container {
        flex-direction: column;
    }

    .map-container {
        width: 100%;
    }

    .map-png-container {
        width: 50%;
    }
}

@media (max-width: 500px) {
    .contact-with-map {
        width: 100%;
        height: 80%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .contact-container {
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .contact-label {
        font-size: 18px;
        font-weight: bold;
        margin: 0 15px 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-adress {
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }

    .map-container {
        margin-top: 10px;
        width: 90%;
    }

    .map {
        height: 300px;
        width: 100%;
        margin: 0 !important;
    }

    .map-png-container {
        height: 300px;
        width: 100% !important;
    }

    .map-png {
        width: auto;
        height: 100%;
    }
}

@media (max-width: 300px) {
    .phone-and-mail a {
        margin: 0 5px;
    }
}

@media (max-height: 660px) and (min-width: 500px) {
    .contact-with-map {
        margin-top: 20px;
    }

    .phone-and-mail {
        flex-direction: row;
    }

    .map {
        justify-content: flex-start;
        margin-left: 50px;
    }

    .map-png-container {
        width: 50%;
    }
}

@media (max-height: 550px) {
    .contact-with-map {
        display: flex;
        flex-direction: column;
        margin-top: unset;
        justify-content: unset;

    }

    .contact-container {
        justify-content: space-evenly;
        align-items: center;
        margin: 20px 0;
    }

    .contact-label {
        font-size: 18px;
        font-weight: bold;
        margin: 0 15px 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-adress-container {
        padding: 5px;
    }

    .contact-adress {
        margin: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
    }

    .map-container {
        width: 100%;
        height: 75%;
    }

    .map {
        justify-content: flex-start;
    }

    .map-png-container {
        height: 100%;
        width: 50%;
    }

    .phone-and-mail {
        flex-direction: row;
        height: 65px;
    }
}

@media (max-height: 375px) {

    .map-container {
        display: none;
    }

    .phone-and-mail {
        justify-content: space-evenly;
        width: 50%;
        height: 100px;
        flex-direction: column;
    }
}

@media (min-width: 1024px) and (max-height: 800px) {
    .phone-and-mail {
        flex-direction: column;
    }
}

@media (max-width: 350px) and (max-height: 600px) {
    .map-container {
        display: none;
    }

    .phone-and-mail {
        flex-direction: column;
    }
}

@media (max-height: 450px) and (max-width: 950px) {
    .contact-with-map {
        height: 200px;
    }
}