* {
    font-family: Open Sans, Arial, sans-serif;
    color: rgb(10, 87, 110);
}

body {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #d7e3ee;
    overflow: auto;
}

.spinner {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.inner-body {
    height: 100%;
    width: 100%;
}

.app {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1024px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.7);
    height: 100vh;
    overflow: hidden;
}

.app.active {
    overflow: auto;
    height: unset;
}

.content-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}