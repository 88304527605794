.second-header-container {
    z-index: 1001;
    width: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: row !important;
    position: fixed;
    top: 25px;
    height: 100px;
    transition: 0.5s;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
    border-radius: 0 0 3px 3px;
}

.second-header-container-fixed {
    z-index: 1001;
    position: fixed !important;
    top: 0 !important;
    transition: 0.5s;
    height: 60px;
}

.header-with-tabmenu {
    z-index: 1000;
    display: flex;
    flex-direction: row !important;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100%;
    transition: 0.5s;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 3px 3px;
}

.second-header {
    top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100px;
    transition: 0.5s;
}

.header-with-tabmenu-fixed {
    height: 60px;
}

.second-header-fixed {
    top: 10px;
    height: 60px;
}

.second-header-fixed .logo-container {
    padding: unset;
    height: 40px;
}

.logo-container {
    margin: 0 0 0 10px;
    padding: 10px 0;
    height: 100%;
    transition: 0.5s;
}

.logo {
    height: 100%;
    width: auto;
    margin: 0;
    transition: 0.5s;
}

.label {
    display: flex;
    text-decoration: none;
    color: rgb(10, 87, 110);;
    font-weight: bold;
    font-size: small;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    align-items: center;
    transition: 0.5s;
}

.label-fixed {
    font-size: small !important;
}

.hamburger-container {
    display: none;
}

.tabmenu-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.tabmenu * {
    border-radius: 0;
    background-color: unset !important;
}

.tabmenu {
    display: flex;
    justify-content: flex-end;
}

.p-tabmenu-nav {
    border-bottom: 150px;
}

.p-tabmenu-nav {
    width: 100%;
    display: flex !important;
    flex-direction: row !important;
    list-style: none;
    padding: unset !important;
}

.p-tabmenuitem :hover {
    text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.p-menuitem-link {
    background-color: unset !important;
    border-radius: 0px !important;
    border: 0 !important;
    height: 30px;
    text-decoration: unset !important;
    padding: 0.3rem 0.5rem !important;
    margin: 0.4rem 0.5rem !important;
    display: unset !important;
}

.p-tabmenu-ink-bar {
    width: 0 !important;
    height: 0 !important;
}

.p-tabmenuitem.p-highlight .p-menuitem-link {
    color: rgba(0, 0, 0, 0.9) !important;
    box-shadow: 0 4px 8px rgb(10, 87, 110);
}

@media (max-width: 847px) {
    .second-header-container {
        flex-direction: column !important;
        display: flex;
        align-items: unset;
        width: 100vw;
        overflow: hidden;
        height: 100px;
    }

    .header-with-tabmenu {
        display: flex;
        width: 100vw;
        height: 100px;
        align-items: center;
        position: fixed;
        clip-path: inset(0);
    }

    .p-tabmenuitem.p-highlight .p-menuitem-link {
        box-shadow: none;
        text-shadow: 0 4px 8px rgba(10, 87, 110, 0.9);
    }

    .second-header {
        justify-content: space-around;
        height: 100px;
        position: fixed;
        top: 25px !important;
    }

    .second-header-container-fixed {
        height: 60px !important;
    } 
    
    .header-with-tabmenu-fixed {
        height: 60px;
    }
    
    .second-header-fixed {
        height: 60px;
        top: 0px !important;
    }

    .second-header-container.active {
        height: 200px !important;
    }

    .second-header-container-fixed.active {
        height: 200px !important;
    }

    .header-with-tabmenu.active {
        height: 200px;
    }

    .logo-container {
        margin: 0 0 0 10px;
        padding: unset;
        justify-content: unset;
        height: 50px;
        display: flex;
        align-items: center;
    }

    .logo {
        height: 50px;
    }

    .label {
        font-size: small !important;
        width: unset;
        margin-left: -2%;
    }

    .hamburger-container {
        display: flex;
        flex-direction: row-reverse;
    }

    .tabmenu-container {
        display: none;
        height: auto;
        position: fixed;
        top: 110px !important;
        width: 100%;
    }

    .tabmenu {
        overflow-x: unset;
        width: 100%;
    }

    .tabmenu-container.active {
        display: flex !important;
        top: 125px;
    }

    .tabmenu-container-fixed {
        top: 85px !important;
    }

    .p-tabmenu-nav {
        align-items: center;
    }

    .p-tabmenuitem {
        margin: 2px 0;
    }

    .p-menuitem-link {
        font-size: medium;
    }

    .tabmenu-container.active .p-tabmenu-nav {
        flex-direction: column !important;
    }
}

@media (max-width: 371px) {
    .second-header-container {
        z-index: 1002;
        width: 100vw;
        display: flex;
        flex-direction: row !important;
        position: fixed;
        top: 50px;
        transition: 0.5s;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    }

    .second-header {
        top: 50px !important;
    }

    .second-header-fixed {
        top: 0 !important;
    }

    .tabmenu-container.active {
        top: 130px !important;
    }

    .tabmenu-container-fixed.active {
        top: 80px !important;
    }

    .p-menuitem-link {
        font-size: small;
    }
}

@media (max-width: 300px) {
    .second-header {
        height: 100px;
    }

    .second-header-fixed {
        height: 60px;
    }

    .tabmenu-container {
        top: 140px !important;
    }

    .tabmenu-container-fixed {
        top: 90px !important;
    }
}