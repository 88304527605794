.start {
    display: flex;
    height: 100vh;
    width: 100%;
    max-width: 1024px;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 12;
    background-size: cover;
    background-position: center;
    background-image: url(../resources/start-mirrored.jpg);
    box-shadow: inset 0 0 0 100vh rgba(0, 153, 204, 0.1);
    border-radius: 0 0 18px 18px;
}

.start-content {
    width: 100%;
    font-weight: bold;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 12;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    border-radius: 0 0 18px 18px;
}

.welcome-container {
    margin: 15% 10%;
    min-width: 275px;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 5px;
}

.welcome-label {
    font-size: 55px;
    font-weight: 1000;
}

.welcome {
    flex-direction: row;
    font-size: 20px;
    height: auto;
    max-width: 600px;
    margin-bottom: 35px;
}

.welcome-text-container {
    font-size: 16px;
    max-width: 600px;
}

.welcome-text-one, .welcome-text-two, .welcome-text-three {
    margin-bottom: 15px;
}

.start.invisible {
    height: 0px;
}

.start-content.invisible {
    height: 0px;
}

.welcome-container.invisible {
    display: none;
}

@media (max-width: 1024px) {

    .start {
        background-image: url(../resources/start.jpg);
        box-shadow: inset 0 0 0 1000px rgba(0, 153, 204, 0.1);
    }

    .welcome-container {
        margin: 15% 35px;
        min-width: 100px;
    }

    .welcome-label {
        font-size: 45px;
        margin-bottom: 5px;
    }

    .welcome {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .welcome-text-container {
        font-size: 17px;
        max-width: 80%;
    }
    
    .welcome-text-one, .welcome-text-two, .welcome-text-three {
        margin-bottom: 10px;
    }
}

@media (max-width: 947px) {

    .welcome-container {
        margin: 17% 50px;
        min-width: 100px;
        max-width: 100%;
    }

    .welcome-label {
        font-size: 30px;
        margin-bottom: 5px;
        max-width: 40%;
    }

    .welcome {
        font-size: x-large;
    }
}

@media (max-width: 700px) {
    .welcome-container {
        margin: 23% 50px;
        min-width: 100px;
        max-width: 100%;
    }

    .welcome-label {
        font-size: 35px;
        max-width: 50%;
    }
}

@media (max-width: 547px) {

    .welcome-container {
        margin: 160px 25px;
        min-width: 100px;
    }

    .welcome-label {
        max-width: 70%;
        font-size: 25px;
    }

    .welcome {
        font-size: 15px;
    }

    .welcome-text-container {
        max-width: 100%;
    }

    .welcome-text-one, .welcome-text-two, .welcome-text-three {
        margin-bottom: 10px;
        font-size: small;
    }
}

@media (max-height: 650px) {

    .welcome {
        margin: 5px 0;
    } 

    .welcome-text-three {
        display: none;
    }
}

@media (max-height: 500px) {
    .welcome-container {
        margin: 155px 25px;
        min-width: 100px;
    }

    .welcome-label {
        font-size: 18px;
    }

    .welcome {
        font-size: 15px;
        margin-bottom: 15px;
    }

    .welcome-text-one, .welcome-text-two {
        margin-bottom: 10px;
        font-size: 12px;
    }
}

@media (max-height: 300px) {
    .welcome-container {
        margin: 135px 25px;
        min-width: 100px;
    }

    .welcome-label {
        font-size: 15px;
        font-weight: bold;
    }

    .welcome {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .welcome-text-one, .welcome-text-two {
        margin-bottom: 5px;
        font-size: 11px;
    }
}

@media (max-width: 500px) and (max-height: 320px) {
    .welcome-container {
        margin-top: 140px; 
    }

    .welcome-label {
        display: none;
    }
}