.news {
    z-index: 11;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    background-color: rgb(255, 255, 255);
    background-size: auto 45%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-image: url(../resources/logo-praxis.png);
    background-color: white;
    overflow-y: visible;
}

.news-container {
    max-width: 50%;
    padding: 20px;
    box-shadow: 0 0 8px rgba(133, 45, 45, 0.6);
    margin: 75px 0;
    background-color: rgba(255, 255, 255, 0.85);
}

.news-container * {
    color: rgb(10, 87, 110)
}

.news-container ul {
    padding-inline-start: 20px !important;
}

.news-label {
    font-size: xx-large;
    margin-bottom: 20px;
    text-decoration-color: rgba(0, 0, 0, 0.05);
}

.crit-li {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 3px;
}

.headline {
    font-weight: bold;
    margin-right: 5px;
    min-width: 225px;
}

.space {
    margin-top: 15px;
}

@media (max-width: 847px) {

    .news {
        flex-direction: column;
        background-size: auto 32%;
    }

    .news-container {
        max-width: 98%;
    }

    .crit-li {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 3px;
    }

    .crit-li * {
        font-size: small !important;
    }

    .headline {
        font-weight: bold;
        margin-right: 5px;
        min-width: 225px;
    }
}

@media (max-width: 400px) {

    .news-container * {
        font-size: 13px;
    }
}

@media (max-width: 300px) {

    .news {
        background-size: auto 22%;
    }

    .news-container {
        padding: 5px;
    }
}

@media (max-height: 505px) {
    .news {
        flex-direction: row;
    }

    .news-container {
        padding: 5px;
    }

    .crit {
        font-size: small;
    }
}

@media (min-width: 615px) {
    .news-container {
        max-width: 615px;
    }
}